import React from "react";
import { createRoot } from "react-dom/client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import "./style/index.css";
import App from "./App";
import { Helmet } from "react-helmet";
const container = document.getElementById("root");
const root = createRoot(container); // Create a root.

root.render(
  <>
    <Helmet>
      <meta
        name="description"
        content="Explore top-notch digital fabric printing services with Ashtamangal Prints. Customize your textile design with eco-friendly, high-quality printing solutions."
      />
    </Helmet>

    <App />
  </>
);
