import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion"; // Import Framer Motion
import myFont from "./../../../src/assets/fonts/vegawanty.otf";
import { Link } from "react-scroll";
import FullButton from "../Buttons/FullButton";
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

import HeaderImage1 from "../../assets/img/BigScreenHeroImages/AP_Big2.webp";
import HeaderImage2 from "../../assets/img/BigScreenHeroImages/AP_Big3.webp";
import HeaderImage3 from "../../assets/img/BigScreenHeroImages/AP_Big4.webp";
import HeaderImage4 from "../../assets/img/BigScreenHeroImages/AP_Big1.webp";
// import HeaderImage5 from "../../assets/img/BigScreenHeroImages/AP_Big2.webp";

import M1 from "../../assets/img/about/bg10.webp";
import M2 from "../../assets/img/about/bg5.webp";
import M3 from "../../assets/img/about/bg8.webp";
import M4 from "../../assets/img/about/bg7.webp";
import M5 from "../../assets/img/about/bg2.webp";

import { Helmet } from "react-helmet";

export default function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => setOffsetY(window.scrollY / 5);

  const desktopImages = [
    HeaderImage1,
    HeaderImage2,
    HeaderImage3,
    HeaderImage4,
    // HeaderImage5,
  ];
  const mobileImages = [M1, M2, M3, M4, M5];
  const headerImages = isMobile ? mobileImages : desktopImages;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Explore the frontier of digital fabric printing with Ashtamangal Prints. Transforming your textile designs into reality with precision and creativity."
        />
      </Helmet>
      <GlobalStyle />
      <Wrapper id="home">
        <ImageWrapper>
          <Carousel
            autoPlay
            interval={3000}
            infiniteLoop
            showThumbs={false}
            showArrows={false}
          >
            {headerImages.map((x, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <LazyLoadImage
                  src={x}
                  alt="Hero-Images for Ashtamangal Prints"
                  style={{
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </motion.div>
            ))}
          </Carousel>
          <Overlay />
        </ImageWrapper>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          style={{ transform: `translateY(${offsetY}px)` }}
        >
          <ContentWrapper className="container flexSpaceCenter">
            <LeftSide>
              <h1>Ashtamangal Prints.</h1>
              <HeaderP>
                Unleashing Creativity Through Digital Precision.
              </HeaderP>
              <BtnWrapper>
                <Link to="contact">
                  <FullButton
                    title="Contact Us"
                    aria-label="Navigate to contact section"
                  />
                </Link>
              </BtnWrapper>
            </LeftSide>
          </ContentWrapper>
        </motion.div>
      </Wrapper>
    </>
  );
}

// Add your styled components and global styles as they were

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'golden';
    src: url(${myFont}) format('opentype');
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// The Section Wrapper
const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    width: 100% !important;
    height: 100vh !important;
    object-fit: cover !important;
    animation: ${fadeIn} 2s ease-out forwards;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: radial-gradient(
    circle at center,
    rgba(26, 26, 26, 0.55) -10%,
    rgba(26, 26, 26, 0.45) 25%,
    rgba(26, 26, 26, 0.35) 50%,
    rgba(26, 26, 26, 0.25) 75%,
    rgba(26, 26, 26, 0.15) 100%
  );
  z-index: 1;
`;

const ContentWrapper = styled.div`
  z-index: 2;
  animation: ${fadeIn} 1.5s ease-out forwards;
  position: relative;
  padding-top: 80px; // Give some space at the top
`;

const LeftSide = styled.div`
  width: auto;
  text-align: left; // Default alignment
  h1 {
    font-size: 7rem; // Large screens
    font-family: "golden", sans-serif;
    color: #f5f5dc; // A bright, off-white color for contrast
    text-shadow: 6px 6px 10px rgba(0, 0, 0, 1); // Enhanced shadow for better readability
    margin-bottom: 0.2em;
    transition: all 0.3s ease-in-out; // Smooth transition for resizing
    @media (max-width: 960px) {
      font-size: 5rem; // Slightly larger for smaller screens
      text-align: center; // Center align for smaller screens
    }
    @media (max-width: 768px) {
      font-size: 4rem; // Adjusted for tablet size
    }
    @media (max-width: 480px) {
      font-size: 2.5rem; // Adjusted for mobile
      margin-bottom: 0.5em;
    }
  }
  @media (max-width: 960px) {
    order: 2;
    text-align: center; // Ensure text alignment is consistent with header
  }
`;

const HeaderP = styled.p`
  max-width: auto;
  margin: auto; // Centers the paragraph within its container
  padding: 15px;
  line-height: 1.6rem;
  font-family: "Khula", sans-serif;
  font-size: 1.6rem;
  color: #ddd;
  text-shadow: 2px 2px 4px rgba(16, 16, 16, 1);
  text-align: center; // Text aligned center by default

  @media (max-width: 960px) {
    text-align: center; // Ensure text alignment is centered for medium screens
  }
  @media (max-width: 768px) {
    text-align: center; // Ensure text alignment is centered for tablets
  }
  @media (max-width: 480px) {
    text-align: center; // Ensure text alignment is centered for mobile phones
    font-size: 1.2rem; // Adjust font size for mobile phones for better readability
  }
`;

const BtnWrapper = styled.div`
  z-index: 10;
  max-width: 190px;
  display: block; // Ensure the button wrapper is properly aligned
  margin: 1em auto; // Center the button on the page
  button {
    font-family: "Khula", sans-serif;
    font-weight: 600;
    letter-spacing: 1.2px; // Slightly increase letter-spacing
    padding: 1em 2em;
    background-color: #f5f5dc; // Match with text color for consistency
    color: #1a1a1a; // Dark text for the button for contrast
    border: none; // Remove border for a cleaner look
    border-radius: 5px; // Slight rounding of corners
    transition: all 0.3s ease-out; // Smooth transition for hover effects
    &:hover {
      transform: translateY(-5px); // More noticeable hover effect
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25); // Slightly larger shadow for better effect
    }
  }
  @media (max-width: 960px) {
    margin: 2em auto; // Increase margin top and bottom for better spacing
  }
`;
