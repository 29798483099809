import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 2px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for all changes

  :hover {
    background-color: ${(props) =>
      props.border ? "rgba(118, 32, 255, 0.1)" : "#580cd2"};
    border: 2px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); // Slightly larger shadow on hover for depth
  }
`;
