import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/logo.webp";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);

    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      if (window.innerWidth > 760 && sidebarOpen) {
        toggleSidebar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [y, sidebarOpen, toggleSidebar]);

  const logoStyle = {
    maxWidth: window.innerWidth <= 760 ? "40px" : "50px",
  };

  const textStyle = {
    marginLeft: "15px",
    fontSize: window.innerWidth <= 760 ? "16px" : "20px",
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <nav>
        <Wrapper
          className="flexCenter animate"
          style={
            y > 100
              ? { height: "60px", background: "rgba(255, 255, 255, 1)" }
              : { height: "80px", background: "rgba(255, 255, 255, 0.95)" }
          }
        >
          <NavInner className="container flexSpaceCenter">
            <Link className="pointer flexNullCenter" to="home" smooth={true}>
              <img
                src={LogoIcon}
                alt="Ashtamangal Prints Logo"
                style={logoStyle}
              />
              <h1 style={textStyle} className="font20 extraBold">
                Ashtamangal Prints
              </h1>
            </Link>
            <BurderWrapper
              className="pointer"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Home
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  About Us
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Services
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="products"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Products
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="blog"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Blog
                </Link>
              </li>
            </UlWrapper>
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer flexCenter">
                <Link
                  className="radius8 lightBg"
                  style={{ padding: "10px 15px" }}
                  to="contact"
                  // spy={true}
                  // smooth={true}
                  // offset={-80}
                >
                  Contact
                </Link>
              </li>
            </UlWrapperRight>
          </NavInner>
        </Wrapper>
      </nav>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 15px;
  display: none;

  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 760px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  list-style: none;

  @media (max-width: 760px) {
    display: none;
  }
`;
