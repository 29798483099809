import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ProductBox({ images = [], title, text, action }) {
  return (
    <Wrapper>
      <Carousel
        autoPlay={true}
        interval={2500}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        dynamicHeight={false}
        swipeable={true}
        showIndicators={false}
        infiniteLoop={true}
        stopOnHover={true}
      >
        {images.map((imgSrc, index) => (
          <div key={index}>
            <img
              className="radius8"
              src={imgSrc}
              alt={`Product ${index + 1}`}
            />
          </div>
        ))}
      </Carousel>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;

  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    img {
      max-width: 80%; // Makes images smaller on mobile screens
      margin: 20px auto; // Centers the image
    }
  }

  h3 {
    padding-bottom: 10px;
  }
`;

// const ImgBtn = styled.button`
//   background-color: transparent;
//   border: 0px;
//   outline: none;
//   padding: 0px;
//   margin: 0px;
//   :hover > img {
//     opacity: 0.5;
//   }
// `;
