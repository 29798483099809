import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Components
// import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";

export default function Services() {
  return (
    <Wrapper id="services">
      <section className="whiteBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo
            as={motion.div}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font13">
              Explore the array of services we offer at Ashtamangal Prints, each
              tailored to meet your specific needs and elevate your creative
              projects.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper
              as={motion.div}
              initial={{ opacity: 0, x: -50 }} // Change x: -50 to x: 50 for alternate items if you want
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }} // Adjust delay as needed
              viewport={{ once: true }}
            >
              <ServiceBox
                icon="roller"
                title="Custom Fabric Printing"
                subtitle="Experience our expertise in printing on a wide variety of materials, including viscose, cotton, and embroidered fabrics. We transform your chosen materials into vibrant canvases for your creative visions."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper
              as={motion.div}
              initial={{ opacity: 0, x: -50 }} // Change x: -50 to x: 50 for alternate items if you want
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }} // Adjust delay as needed
              viewport={{ once: true }}
            >
              <ServiceBox
                icon="monitor"
                title="Design Masterpieces"
                subtitle="Our team of expert and creative designers utilizes world-class tools to craft design masterpieces tailored to your unique aesthetic. Unleash your creativity with designs that leave a lasting impression."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper
              as={motion.div}
              initial={{ opacity: 0, x: -50 }} // Change x: -50 to x: 50 for alternate items if you want
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }} // Adjust delay as needed
              viewport={{ once: true }}
            >
              <ServiceBox
                icon="browser"
                title="Idea to Reality"
                subtitle="Bring your ideas to life with our expert designers and top-notch printing services. We work collaboratively to ensure your concepts are transformed into stunning visual creations."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper
              as={motion.div}
              initial={{ opacity: 0, x: -50 }} // Change x: -50 to x: 50 for alternate items if you want
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }} // Adjust delay as needed
              viewport={{ once: true }}
            >
              <ServiceBox
                icon="printer"
                title="Production Excellence"
                subtitle="Our commitment to quality shines through as we use high-quality ink and world-class printers for bulk production. Expect top-tier results that make your projects stand out."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </section>

      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <motion.div
          // initial={{ x: 200 }}
          // whileInView={{ x: 0 }}
          // transition={{ duration: 0.78, delay: 0.2 }}
          >
            <ClientSlider />
          </motion.div>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
