import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/about/4.avif";
import AddImage2 from "../../assets/img/about/3.avif";
import AddImage3 from "../../assets/img/about/14.avif";
import AddImage4 from "../../assets/img/about/6.avif";
import { Link } from "react-scroll";
export default function About() {
  return (
    <Wrapper id="about">
      <section className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <motion.h4
                  as="article"
                  className="font15 semiBold"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  A few words about company
                </motion.h4>
                <motion.h2
                  className="font40 extraBold"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  viewport={{ once: true, amount: 0.3 }}
                >
                  About Us
                </motion.h2>
                <motion.p
                  className="font12"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  viewport={{ once: true, amount: 0.4 }}
                >
                  Founded with a vision to redefine the possibilities of fabric
                  printing, Ashtamangal Prints seamlessly blends tradition with
                  technology. Our journey began with a deep-rooted passion for
                  textiles and a commitment to delivering exceptional quality.
                  Over the years, we have evolved into a trusted name, standing
                  at the forefront of the digital printing industry.
                </motion.p>

                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <motion.div
                    whileInView={{ opacity: 1, translateY: 0 }}
                    initial={{ opacity: 0, translateY: 20 }}
                    transition={{ duration: 0.65, delay: 0.4 }}
                    viewport={{ once: true, amount: 0.5 }}
                    style={{ width: "190px" }}
                  >
                    <Link to="contact">
                      <FullButton title="Get Started" />
                    </Link>
                  </motion.div>
                  <motion.div
                    whileInView={{ opacity: 1, translateY: 0 }}
                    initial={{ opacity: 0, translateY: 20 }}
                    transition={{ duration: 0.65, delay: 0.45 }}
                    viewport={{ once: true, amount: 0.5 }}
                    style={{ width: "190px", marginLeft: "15px" }}
                  >
                    <FullButton
                      title="Whatsapp"
                      action={() => window.open("https://wa.me/918000797987")}
                      border
                    />
                  </motion.div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1
                      whileInView={{ opacity: 1, scale: 1 }}
                      initial={{ opacity: 0, scale: 0.4 }}
                      transition={{ duration: 0.63, delay: 0.2 }}
                      viewport={{ once: true }}
                      className="flexCenter"
                    >
                      <img src={AddImage1} alt="AboutAshtamangal Prints" />
                    </AddImgWrapp1>
                    <AddImgWrapp2
                      whileInView={{ opacity: 1, scale: 1 }}
                      initial={{ opacity: 0, scale: 0.4 }}
                      transition={{ duration: 0.78, delay: 0.24 }}
                      viewport={{ once: true }}
                      className="flexCenter"
                    >
                      <img src={AddImage2} alt="AboutAshtamangal Prints" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3
                      whileInView={{ opacity: 1, scale: 1 }}
                      initial={{ opacity: 0, scale: 0.4 }}
                      transition={{ duration: 0.55, delay: 0.2 }}
                      viewport={{ once: true }}
                      className="flexCenter"
                    >
                      <img src={AddImage3} alt="AboutAshtamangal Prints" />
                    </AddImgWrapp3>
                    <AddImgWrapp4
                      whileInView={{ opacity: 1, scale: 1 }}
                      initial={{ opacity: 0, scale: 0.4 }}
                      transition={{ duration: 0.63, delay: 0.3 }}
                      viewport={{ once: true }}
                      className="flexCenter"
                    >
                      <img src={AddImage4} alt="AboutAshtamangal Prints" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled(motion.div)`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled(motion.div)`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled(motion.div)`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled(motion.div)`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
