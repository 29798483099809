import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Components
import ProductBox from "../Elements/ProductBox";
// import FullButton from "../Buttons/FullButton";
// import CarouselComponent from "./../Elements/Carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requires a loader

// Assets
import EthnicImg1 from "../../assets/img/products/ethnic/1.webp";
import EthnicImg2 from "../../assets/img/products/ethnic/4.webp";
import EthnicImg3 from "../../assets/img/products/ethnic/5.webp";
import EthnicImg4 from "../../assets/img/products/ethnic/6.webp";

import Kaftan1 from "../../assets/img/products/kaftan/1.webp";
import Kaftan2 from "../../assets/img/products/kaftan/2.webp";
import Kaftan3 from "../../assets/img/products/kaftan/3.webp";
import Kaftan4 from "../../assets/img/products/kaftan/4.webp";
import Kaftan5 from "../../assets/img/products/kaftan/5.webp";

import kidsImage1 from "../../assets/img/products/child/1.webp";
import kidsImage2 from "../../assets/img/products/child/2.webp";

import homeImage1 from "../../assets/img/products/home/1.webp";
import homeImage2 from "../../assets/img/products/home/2.webp";
import homeImage3 from "../../assets/img/products/home/3.webp";
import homeImage4 from "../../assets/img/products/home/4.webp";

import womenImage1 from "../../assets/img/products/women/1.webp";
import womenImage2 from "../../assets/img/products/women/2.webp";
import womenImage3 from "../../assets/img/products/women/3.webp";
import womenImage4 from "../../assets/img/products/women/4.webp";
import womenImage5 from "../../assets/img/products/women/5.webp";

import menImage1 from "../../assets/img/products/men/1.webp";
import menImage2 from "../../assets/img/products/men/2.webp";
import menImage3 from "../../assets/img/products/men/3.webp";

import menImage5 from "../../assets/img/products/men/5.webp";

export default function Products() {
  // Example images for the carousel
  // const ethnicWearImages = [ProductImg1, ProductImg2, ProductImg3];
  const ethnicImages = [EthnicImg1, EthnicImg2, EthnicImg3, EthnicImg4];
  const kaftanImages = [Kaftan1, Kaftan2, Kaftan3, Kaftan4, Kaftan5];
  const kidsImages = [kidsImage1, kidsImage2];
  const homeImages = [homeImage1, homeImage2, homeImage3, homeImage4];
  const womenImages = [
    womenImage1,
    womenImage2,
    womenImage3,
    womenImage4,
    womenImage5,
  ];
  const menImages = [menImage1, menImage2, menImage3, menImage5];

  const products = [
    {
      title: "Ethnic Wear",
      text: "Our vibrant shades and meticulous craftsmanship, both narrates a tale of tradition and sophistication, inviting you to embrace the essence of timeless fashion.",
      images: ethnicImages,
    },
    {
      title: "Kaftan",
      text: "Our collection embraces the free-spirited with flowing fabrics, captivating designs, and a perfect blend of comfort and charm.",
      images: kaftanImages,
    },
    {
      title: "Kids Wear",
      text: "Our children's wear combines lively designs and snug fits, creating a wardrobe that's as fun and spirited as they are.",
      images: kidsImages,
    },
    {
      title: "Home Furnishing",
      text: "Our vibrant shades and meticulous craftsmanship, both narrates a tale of tradition and sophistication, inviting you to embrace the essence of timeless fashion.",
      images: homeImages,
    },
    {
      title: "Womens Wear",
      text: "Our women’s wear collection offers a mix of timeless elegance and on-trend pieces, ensures one can comfort with style.",
      images: womenImages,
    },
    {
      title: "Mens Wear",
      text: "From men’s casual wear to formals, find the perfect balance of comfort and fashion in our curated selection.",
      images: menImages,
    },
  ];

  return (
    <Wrapper id="products">
      {/* <div className="whiteBg" style={{ padding: "50px 0" }}></div> */}
      <section className="whiteBg">
        <div className="container">
          <HeaderInfo
            as={motion.div}
            initial={{ opacity: 0, x: 45 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <h1 className="font40 extraBold">Our Products</h1>
            <p className="font13">
              Explore a rich array of fabrics produced through digital printing:
              <br />
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {products.map((product, index) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                <ProductsWrapper
                  as={motion.div}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  viewport={{ once: true, amount: 0.1 }}
                >
                  <ProductBox
                    images={product.images}
                    alt="Product Image"
                    title={product.title}
                    text={product.text}
                    action={() => console.log("clicked")}
                  />
                </ProductsWrapper>
              </div>
            ))}
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton
                title="Load More"
                action={() => console.log("clicked")}
              />
            </div>
          </div> */}
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ProductsWrapper = styled.div`
  // ... existing styles ...

  @media (max-width: 768px) {
    padding: 20px; // Reduce padding on smaller screens

    .row {
      margin: 0 -10px; // Adjust row margins if necessary
    }

    .col-xs-12 {
      padding: 0 10px; // Adjust column padding for better spacing
    }

    h1 {
      font-size: 24px; // Reduce the font size of headers
    }

    .font13 {
      font-size: 12px; // Adjust the font size for smaller text
    }

    // Add other responsive adjustments as needed
  }
`;
