import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Components
import BlogBox from "../Elements/BlogBox";
// import FullButton from "../Buttons/FullButton";
// import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <section className="whiteBg" style={{ margin: "50px" }}>
        <div className="container">
          <HeaderInfo
            as={motion.div}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5 }}
            // viewport={{ once: true }}
          >
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            <p className="font13">
              Delve into our latest insights and stories, where tradition meets
              innovation, uncovering the artistry and technology behind modern
              textile creations.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Digital Fabric Printing Explained"
                  text="Discover the future of textiles with digital fabric printing: high-definition designs, rapid production, and eco-friendly solutions."
                  tag="Innovation"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Viscose Fabric Printing Demystified"
                  text="Explore the elegance of viscose fabrics combined with the precision of digital printing, for fashion that's both luxurious and vibrant."
                  tag="FabricInnovation"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 3 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Tech Breakthroughs in Digital Printing"
                  text="Stay ahead with the latest advancements in digital printing technology: faster, clearer, and more sustainable than ever."
                  tag="TechUpdate"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 4 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Why Choose Digital Printing?"
                  text="Uncover the compelling advantages of digital printing: efficiency, customization, and quality, transforming your textile projects."
                  tag="Benefits"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 5 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Digital Printing Customization"
                  text="Revolutionize fabric designs with personalized, innovative digital printing."
                  tag="CustomDesigns"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 6 * 0.13 }}
                viewport={{ once: true, amount: 0.07 }} // Adjust the amount visible for the animation to trigger
              >
                <BlogBox
                  title="Eco-Friendly Digital Printing"
                  text="Embrace sustainability in the textile industry with eco-friendly digital printing techniques that reduce waste and conserve resources."
                  tag="Sustainability"
                  author="Aashtamangal Prints, 2 days ago"
                  // action={() => console.log("clicked")}
                />
              </motion.div>
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton
                title="Load More"
                action={() => console.log("clicked")}
              />
            </div>
          </div> */}
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
