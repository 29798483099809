import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion"; // Import motion here
import { Helmet } from "react-helmet";

export default function Contact() {
  const handleButtonClick = () => {
    // Replace 'Subject' and 'Message' with your desired strings
    const subject = document.getElementById("subject").value;
    const message = document.getElementById("message").value;
    const name = document.getElementById("name").value;
    const fullMessage = message + "\nName:\n" + name;
    // Construct the mailto URL with subject and message
    const mailtoURL = `mailto:aashtamangalprints@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(fullMessage)}`;

    // Redirect to the mailto URL
    window.location.href = mailtoURL;
  };

  return (
    <Wrapper id="contact">
      <Helmet>
        <meta
          name="description"
          content="Reach out to Ashtamangal Prints for innovative textile solutions and services. Contact us today."
        />
      </Helmet>
      <section className="lightBg">
        <div className="container">
          <HeaderInfo
            as={motion.div}
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Choose Ashatamangal Prints for an exceptional blend of technology,
              creativity, and commitment to quality. Contact us today to explore
              how we can elevate your textile and printing requirements.
              <br />
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form
                as={motion.div}
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <label className="font13 visuallyHidden" htmlFor="name">
                  First name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="font20 extraBold"
                />
                <label className="font13 visuallyHidden" htmlFor="email">
                  Email:
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                  required
                />
                <label className="font13 visuallyHidden" htmlFor="subject">
                  Subject:
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  className="font20 extraBold"
                />
                <label
                  className="font13 visuallyHidden"
                  required
                  htmlFor="message"
                >
                  Message:
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                />
              </Form>
              <SumbitWrapper
                className="flex"
                as={motion.div}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <ButtonInput
                  type="button"
                  value="Send Message"
                  className="pointer animate radius8"
                  style={{ maxWidth: "220px" }}
                  onClick={handleButtonClick}
                  aria-label="Send email to Ashtamangal Prints"
                />
              </SumbitWrapper>
            </div>
            <motion.div
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex"
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              viewport={{ once: true }}
            >
              <div
                style={{ width: "100%" }}
                className="flexNullCenter flexColumn"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3720.3090109330765!2d72.82796097526077!3d21.17988008050758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDEwJzQ3LjYiTiA3MsKwNDknNDkuOSJF!5e0!3m2!1sen!2sth!4v1706735434873!5m2!1sen!2sth"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "0",
                    padding: "5%",
                  }}
                  loading="lazy"
                  allowFullScreen
                  title="Location of Ashtamangal Prints"
                ></iframe>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

// const ContactImgBox = styled.div`
//   max-width: 180px;
//   align-self: flex-end;
//   margin: 10px 15px 10px 0;
//   img {
//     object-fit: cover; // Do not scale the image
//     max-width: 100%; // Ensures image doesn't exceed the width of the container
//     height: auto; // Maintain aspect ratio
//     display: block; // Remove extra space below the image
//   }
//   @media (max-width: 768px) {
//     .contact-img-box img {
//       max-width: 100%; // Adjust as per mobile layout
//       height: auto; // Maintain aspect ratio
//     }
//   }
// `;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
