import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import ReactGA from "react-ga4";

export default function App() {
  useEffect(() => {
    // ReactGA.initialize("G-0PMDMBG6KS");
    ReactGA.initialize(toString(process.env.MEASUREMENT_ID));
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover premium digital fabric printing services with Ashtamangal Prints. Specializing in custom designs, eco-friendly processes, and high-quality results."
        />
        <meta
          name="keywords"
          content="digital fabric printing, custom textile design, eco-friendly printing, high-quality fabric prints, Ashtamangal Prints"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:wght@400;600;800&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Landing />
    </>
  );
}
