import React, { useEffect, useRef } from "react";

// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import About from "../components/Sections/About";
import Mission from "../components/Sections/Mission";
import Blog from "../components/Sections/Blog";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import Products from "../components/Sections/Products";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import whatsappAvatar from "./logo.webp";
import LocomotiveScroll from "locomotive-scroll";

const msg =
  "Welcome to Ashtamangal Prints!" +
  "\n" +
  "How can we enhance your textile projects today?";

export default function Landing() {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      const locomotiveScroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
        inertia: 0.75, // Slightly reduced inertia for more natural feel
        getSpeed: true,
        getDirection: true,
        smartphone: {
          smooth: true,
          inertia: 0.75, // Consistent with the general setting for a uniform experience
        },
        tablet: {
          smooth: true,
          inertia: 0.75, // Consistent with the general setting for a uniform experience
        },
        reloadOnContextChange: true,
        lerp: 0.1, // Keeping linear interpolation low for responsiveness
      });

      scrollRef.current.addEventListener(
        "load",
        () => {
          locomotiveScroll.update();
        },
        { capture: true, passive: true }
      );

      return () => locomotiveScroll.destroy();
    }
  }, []);

  return (
    // <div ref={scrollRef} data-scroll-container>
    <>
      <TopNavbar />
      <Header />
      <About />
      <Mission />
      <Services />
      <Products />
      <Blog />
      <Contact />
      <Footer />
      <FloatingWhatsApp
        phoneNumber="918000797987"
        accountName="Ashtamangal Prints"
        avatar={whatsappAvatar}
        statusMessage="Online"
        messageDelay={1.63}
        chatMessage={msg}
        allowClickAway
        allowEsc
      />
    </>
    // </div>
  );
}
