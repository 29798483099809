import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion"; // Import motion
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AboutUsVideo from "./../../assets/img/about/about.webm";

export default function Mission() {
  return (
    <Wrapper id="mission">
      <section className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft as="article">
              <AddLeftInner>
                <div className="container">
                  <ImgWrapper className="flexCenter">
                    <motion.video
                      className="radius8"
                      src={AboutUsVideo} // using the video from your import
                      alt="Showcasing Ashtamangal Prints technology"
                      initial={{ opacity: 0, x: -100 }} // Start from left and faded
                      whileInView={{ opacity: 1, x: 0 }} // Animate to original position and visible
                      transition={{ duration: 0.8 }} // Duration of animation
                      muted
                      autoPlay
                      loop
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Ensure the video covers the area without stretching
                    />
                  </ImgWrapper>
                </div>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <motion.h4
                className="font15 semiBold"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                Join the Ashtamangal Family
              </motion.h4>
              <motion.h2
                className="font40 extraBold"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                Our Vision, Our Expertise
              </motion.h2>
              <motion.p
                className="font12"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                Ashtamangal Prints is more than a brand; it's a community of
                creators, designers, and visionaries. Explore the boundless
                potential of digital printing with us, and let your ideas come
                to life in vibrant hues and intricate details.
                <br />
                <br />
                Discover the art of digital printing with Ashtamangal Prints –
                where craftsmanship meets technology, and innovation knows no
                bounds.
              </motion.p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.65 }}
                  style={{ width: "190px" }}
                >
                  <FullButton
                    title="Get Started"
                    action={() => console.log("clicked")}
                  />
                </motion.div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  position: relative;
  overflow: hidden;

  @media (max-width: 400px) {
    padding: 0;
  }
`;
