import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/logo.webp";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const isMobile = window.innerWidth <= 768;

  return (
    <Wrapper
      className="animate darkBg"
      sidebarOpen={sidebarOpen}
      isMobile={isMobile}
    >
      <SidebarHeader isMobile={isMobile} className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img
            src={LogoIcon}
            alt="Ashtamangal Prints Logo"
            style={{ maxWidth: 20 }}
          />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            Ashtamangal Prints
          </h1>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle isMobile={isMobile} className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Navigate back to the homepage of Ashtamangal Prints"
          >
            Home
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="about"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Learn more about Ashtamangal Prints"
          >
            About
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Navigate to the Services section to learn more about what Ashtamangal Prints offers"
          >
            Services
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="products"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Navigate to the Products section to explore Ashtamangal Prints' offerings"
          >
            Products
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Navigate to the Blog section to read the latest articles and updates from Ashtamangal Prints"
          >
            Blogs
          </Link>
        </li>
      </UlStyle>
      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
            aria-label="Navigate to the Contact Us section for inquiries and more information about Ashtamangal Prints"
          >
            Contact Us
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => window.open("https://wa.me/918000797987")}
            activeClass="active"
            className="whiteColor semiBold"
            style={{
              padding: "10px 15px",
              backgroundColor: "#25d366",
              borderRadius: "12px",
            }}
            spy={true}
            smooth={true}
            offset={-60}
            to="#"
            aria-label="Connect with Ashtamangal Prints on WhatsApp for direct support and inquiries"
          >
            Whatsapp
          </Link>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-420px")};
  z-index: 9999;
  @media (max-width: 768px) {
    width: 300px; // Adjust for medium screens
  }

  @media (max-width: 420px) {
    width: 100%; // Full width for small screens
    right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
  img {
    max-width: ${(props) =>
      props.isMobile ? "15px" : "20px"}; // Adjust based on a prop
  }
  h1 {
    font-size: ${(props) =>
      props.isMobile ? "16px" : "20px"}; // Adjust font size
  }
`;

const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 420px) {
    padding: 15px; // Larger touch target
  }
`;
const UlStyle = styled.ul`
  padding: 20px; // Reduced padding for smaller screens
  li {
    margin: 15px 0; // Adjust margin
    font-size: ${(props) =>
      props.isMobile ? "14px" : "15px"}; // Adjust font size
  }
`;
